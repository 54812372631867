import React from "react";
import { AppProvider } from "./src/components/AppContext";

import "./src/styles/global.css";
import "./src/styles/cookies.css";

import "@fontsource/karla/400.css";
import "@fontsource/karla/400-italic.css";
import "@fontsource/karla/500.css";
import "@fontsource/karla/500-italic.css";
import "@fontsource/karla/600.css";
import "@fontsource/karla/600-italic.css";
import "@fontsource/karla/700.css";
import "@fontsource/karla/700-italic.css";
import "@fontsource/karla/800.css";
import "@fontsource/karla/800-italic.css";

import "@fontsource/kanit";
import "@fontsource/kanit/500.css";
import "@fontsource/kanit/600.css";
import "@fontsource/kanit/700.css";
import "@fontsource/kanit/800.css";

export const wrapRootElement = ({ element }) => (
  <AppProvider>{element}</AppProvider>
);
